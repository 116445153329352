@font-face{
  font-family: 'Open Sans';
  src: local('OpenSans'), url('./fonts/OpenSans-Regular.ttf') format('truetype');
}
@font-face{
  font-family: 'Abel Regular';
  src: local('Abel Regular'), url('./fonts/Abel-Regular.ttf') format('truetype');
  
}
html, body {
  margin: 0;
  font-family: 'Abel Regular', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: auto;
  font-size: 1rem !important;
}

.pvtUi, .pvtTable {
  font-family: 'Abel Regular', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: auto;
  font-size: 1rem !important;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.container{
  max-width: 1500px!important;
  background-image: none !important;
}

.market-structure-list button{
  font-size: 0.9rem !important;
  margin-bottom: 3px!important;
}

#idMarketStructureTabs .nav-link {
  padding: .5rem .5rem !important;
  font-size: 0.8rem !important;
}

.ejmWVz{
  font-size: 18px !important;
  min-height: 40px !important;
}

.data-types-list{
  margin-bottom: 2rem;
}

.data-types-list .alert{
  margin-bottom: 0.1rem !important;
}

.data-types-files{
  border-radius: 10px;
  border: 2px solid #CCE5FF;
}

.data-series-left{
  overflow-y: auto;
  max-height: 80vh;
}
.data-series-file{
  padding: 2px;
}

.form-label{
  width: 100% !important;
}

.pending-user{
  background-color: #FFC312 !important;
}

.expired-user{
  background-color: #f94144 !important;
}

.active-user{
  background-color: #99d98c !important;
}

.filter-title{
  text-transform: uppercase;
  font-weight: bold;
  color: #39737cff;
  float: left;
}

.recharts-surface {
  overflow: visible !important;
}

.recharts-cartesian-axis {
  font-size: 0.8em !important;
}

.nav-tabs a{
  font-size: 13px !important;
}

.recharts-component{
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 10px;
  width: 100%;
  height: 100%;
  background-color: #fff;
  margin-bottom: 50px;
}

.recharts-legend-wrapper{
  font-size: 14px !important;
}

.no-padding{
  padding-right: 0px !important;
  padding-left: 0px !important;
}

.react-datepicker-popper{
  z-index: 999 !important;
}

.custom-control-label::before{
  cursor: pointer;
}

.dataSeriesChart {
  height: 600px;
  margin-top: 15px;
  position: relative;
  max-height: 76vh;
  background-color: white;
  border: 2px solid #46737c;
  border-radius: 7px;
  overflow-y: scroll;
}

.layout{
  height: 70vh;
  overflow-y: scroll
}

.market-structure-layout{
  max-height: 75vh;
  overflow-y: scroll;
  background-color: white;
  border: 2px solid #46737c;
  border-radius: 7px;
}

.react-grid-layout{
  background-color: white;
}

.alert-primary{
  background-color: #72a1ab  !important;
  border-color: transparent !important;
  color: white !important;
}

/* svg{
    color: #46737c;
} */
/* path{
  color: #46737c;
  stroke: #46737c;
} */

#icon svg{
  color: white !important;
  stroke: white !important;
}
#icon svg path{
  color: white !important;
  stroke: white !important;
}

b svg{
  color: #39737cff !important;
  stroke: #39737cff !important;
}

b svg path{
  color: #39737cff !important;
  stroke: #39737cff !important;
}


/* Overriding button styles */
.btn-outline-primary{
  background-color: #bbdddfff !important;
  color: #39737cff !important;
  border: none !important;
}
.btn-outline-primary:hover{
  color: white !important;
}

.btn-outline-danger{
  background-color: #bbdddfff !important;
  color: #39737cff !important;
  border: none !important;
}
.btn-outline-danger:hover{
  color: red !important;
}

.btn-outline-success{
  background-color: #bbdddfff !important;
  color: #39737cff !important;
  border: none !important;
  text-decoration: none;
}
.btn-outline-success:hover{
  color: white !important;
}

.btn-outline-success a{
  text-decoration: none !important;
  color: #39737cff !important;
}

.btn-outline-success a:hover{
  color: white !important;
}

.simulation-filters-left {
  padding: 5px !important;
}

.padding-right{
  padding-right: 5px!important;
}

.custom-control-input:checked~.custom-control-label::before{
  border-color: #39737cff !important;
  background-color: #39737cff !important;
}

.fixed-list-simulations {
  overflow-y: auto;
  height: calc(100vh - 220px);
}

.user-container{
  height: calc(100vh - 140px);
  overflow-y: auto;
}

.market-structure-fixed-list {
  max-height: 700px !important;
  overflow-y: auto;
}

/*::-webkit-scrollbar {*/
/*  width: 0;*/
/*  background: transparent; !* make scrollbar transparent *!*/
/*}*/

#idDatatableContainer::-webkit-scrollbar {
  /*  width: 0;*/
  /*  background: transparent; !* make scrollbar transparent *!*/
}

.ag-row{
  height: 30px!important;
}

#idInvestmentsForm .input-group-text{
  padding: 0.3rem 0.65rem !important;
  font-size: 0.7rem !important;
}

.costs-item-width {
  width: 70% !important;
}