.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

/*@media (prefers-reduced-motion: no-preference) {*/
/*  .App-logo {*/
/*    animation: App-logo-spin infinite 20s linear;*/
/*  }*/
/*}*/

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.cardImage {
  cursor: pointer
}

.cardImage:hover {
  opacity: 0.8;
}

.Home{
 padding-top: 50px;
 padding-left: 200px !important;
 padding-right: 200px !important;

}

.home-logo{
  padding: 40px;
}

.home-logo img{
  width: 400px;
}

.card-home{
  border-radius: 10px;
  margin: 25px;
  width: 300px;
  max-height: 300px;
  background-color: #ee9600ff;
  box-shadow:  0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  transition: 0.1s;
  min-height: 370px !important;
}
.card-home:hover{
  cursor: pointer;
  box-shadow:  0 8px 8px 0 rgba(0, 0, 0, 0.5), 0 8px 20px 0 rgba(0, 0, 0, 0.49);

}
.card-header{
  color: white;
}
.card-header img{
  width: 68px;
  height: 68px;
}
.card-header p{
  margin-top: 5px;
  margin-bottom: 0px;
}

.card-body{
  background-color: white;
  bottom: auto;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  min-height: 180px;
}

p{
  color: #39737cff;
}
h1{
  color: #39737cff;
}
.card-header p{
  color: white;
}

.logo-small{
  height: 50px;
}

.navbar{
  background-color: #39737cff !important;
}
.navbar a{
  color: white !important;
}
.navbar a:hover{
  color:  #ee9600ff!important;
}

.navbar span{
  color: white !important;
}
.navbar-collapse{
  margin-top: auto !important;
}

.btn-secondary{
  background-color: #bbdddfff !important;
  color: #39737cff !important;
  border: none !important;
}
.btn-secondary:hover{
  color: white !important;
}

.signed-as{
  padding-left: 5px;
}

.footer{
  background-color: #39737cff !important;
  padding: 10px;
  text-align: left;
  position: absolute !important;
  bottom: 0 !important;
  width: 100%;
  margin-left: -15px;
}
.footer p{
  color: white !important;
  padding-left: 100px;
  margin: 0px !important;
}

.modal-left{
  background-color: white;
  height: 90vh !important;
  padding-right: 5px;
}
.modal-right{
  width: 90vw !important;
  background-size: cover;
  background-repeat: no-repeat;
}
.filler{
  margin-top: 30vh;
  margin-left: 20vw;
  text-align: left;
}

.button-primary-cover{
  background-color: #fddebb !important;
  border-radius: 15px !important;
  border-color: transparent !important;
}
.button-secondary-cover{
  border-radius: 15px !important;
  border-color: transparent !important;
  color: #39737cff;
  background-color: #fddebb85;
}

.button-primary{
  background-color: #ee9700 !important;
  border-color: transparent !important;
  color: white !important;
}
.button-primary:hover{
  color: #39737cff !important;
}

.simulation-primary{
  color: #39737cff !important;
  border-color: #fddebb !important;
}
.simulation-primary :hover{
  color: #fff !important;
  border-color: #fddebb !important;
  background: #fddebb !important;
}

.btn-outline-info {
  color: #39737cff;
  border-color: #39737cff;
}
.btn-outline-info:hover{
  color: #fff !important;
  background-color: #39737cff !important;
  border-color: #39737cff !important;
}
.btn-outline-info:active{
  background-color: #39737cff !important;
  border-color: #39737cff !important;
}
.btn-outline-info:focus{
  box-shadow: 0 0 0 0.2rem #39737cff !important;;
}
.btn-outline-info:focus-visible{
  background-color: #39737cff !important;
  border-color: #39737cff !important;
  color: #fff !important;
  box-shadow: none !important;
}

.simulation-chart{
  border: 2px solid #46737c;
  border-radius: 7px;
  margin: 2px !important;
  padding-top: 10px;
  padding-bottom: 10px;
}

.btn-outline-secondary{
  background-color: #bbdddfff !important;
  color: #39737cff !important;
  border: none !important;
}
.btn-outline-secondary:hover{
  color: white !important;
}

.margin-left-10 {
  margin-left: 10px!important;
}
